<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>導入郵包號</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem">
          <el-form-item prop="post_office_id" label="門店">
            <el-select v-model="form.post_office_id" filterable clearable class="w-50">
              <el-option
                v-for="post_office in currentUser.post_offices"
                :key="post_office.id"
                :label="post_office.name"
                :value="post_office.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="parcel_model_id" label="郵包類型">
            <el-select v-model="form.parcel_model_id" clearable class="w-50">
              <el-option
                v-for="parcel_model in enableParcelModels"
                :key="parcel_model.id"
                :label="parcel_model.name"
                :value="parcel_model.id"
              >
              </el-option>
            </el-select>

            <div class="row mt-3" v-if="form.parcel_model_id">
              <div class="col-3"  v-html="renderParcelModelInfo(form.parcel_model_id)"></div>
            </div>

          </el-form-item>

          <el-form-item prop="code" label="郵包號碼" v-if="form.post_office_id && form.parcel_model_id">
            <el-input ref="idInput" v-model="form.code" clearable placeholder="請輸入郵包號碼" @keyup.enter.native="submit()" />
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submit"
              :disabled="!form.post_office_id || !form.parcel_model_id || !form.code"
            >導入</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import _ from 'lodash';

export default {
  name: 'ImportParcels',
  mounted() {
  },
  data() {
    return {
      form: {
        code: '',
        post_office_id: null,
        parcel_model_id: null,
      },
      formRules: {
        code: { required: true, message: '請輸入郵包號', trigger: 'change' },
        post_office_id: { required: true, message: '請選擇門店', trigger: 'change' },
        parcel_model_id: { required: true, message: '請選擇郵包類型', trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUser', 'enableParcelModels',
    ]),
  },
  methods: {
    renderParcelModelInfo(id) {
      const parcelModel = _.find(this.enableParcelModels, { id });
      if (parcelModel) {
        const size = _.filter([parcelModel.length, parcelModel.width, parcelModel.height])
          .join('&times;');
        return `
          <div class="card border-primary">
            <div class="card-header text-white bg-primary">${parcelModel.name}</div>
            <div class="card-body">
              <ul>
                <li>尺寸：${size} (CM)</li>
                <li>材料：${parcelModel.materiel}</li>
                <li>最大重量：${parcelModel.max_weight} (G)</li>
                <li>价格：HK$${parcelModel.price}</li>
                <li>备注：${parcelModel.remark}</li>
              </ul>
            </div>
          </div>
        `;
      }
      return '';
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.parcel.save(this.form).then(() => {
            this.$notify.success({
              title: '成功導入',
            });
          }).catch(error => showErrorMessage(error)).finally(() => {
            this.form.code = '';
            this.$refs.idInput.focus();
            loading.close();
          });
        }
      });
    },
  },
};
</script>
