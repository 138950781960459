<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>郵包號綁定門店</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem">
          <el-form-item prop="post_office_id" label="門店">
            <el-select v-model="form.post_office_id" filterable clearable class="w-50">
              <el-option
                v-for="post_office in currentUser.post_offices"
                :key="post_office.id"
                :label="post_office.name"
                :value="post_office.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="code" label="郵包號碼" v-if="form.post_office_id">
            <el-input ref="idInput" v-model="form.code" clearable placeholder="請輸入郵包號碼" @keyup.enter.native="submit()" />
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submit"
              :disabled="!form.post_office_id || !form.code"
            >導入</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'BindParcels',
  mounted() {
    if (!this.parcelModels.length) {
      this.$store.dispatch('fetchParcelModels');
    }
  },
  data() {
    return {
      form: {
        code: '',
        post_office_id: null,
      },
      formRules: {
        code: { required: true, message: '請輸入郵包號', trigger: 'change' },
        post_office_id: { required: true, message: '請選擇門店', trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
    ...mapGetters([
      'currentUser',
    ]),
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.parcel.bind(this.form.code, this.form.post_office_id).then(() => {
            this.$notify.success({
              title: '已綁定',
            });
          }).catch(error => showErrorMessage(error)).finally(() => {
            this.form.code = '';
            this.$refs.idInput.focus();
            loading.close();
          });
        }
      });
    },
  },
};
</script>
