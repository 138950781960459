<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>郵包模型管理</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-button type="primary" @click="openDialog()">添加模型</el-button>

        <table class="table table-striped mt-3">
          <tbody>
          <tr>
            <th>名稱</th>
            <th>郵包模型Code</th>
            <th>類型</th>
            <th>物料</th>
            <th>長度</th>
            <th>寬度</th>
            <th>高度</th>
            <th>最大重量</th>
            <th>價格</th>
            <th>備註</th>
            <th>創建時間</th>
            <th>操作</th>
          </tr>
          <tr v-for="p in parcelModels" :key="p.id">
            <td>{{ p.name }}</td>
            <td>{{ p.code }}</td>
            <td>{{ p.type === 1 ? '門店寄件' : '上門取件' }}</td>
            <td>{{ p.type === 1 ? p.materiel : '-' }}</td>
            <td>{{ p.type === 1 ? p.length : '-' }}</td>
            <td>{{ p.type === 1 ? p.width : '-' }}</td>
            <td>{{ p.type === 1 ? p.height : '-' }}</td>
            <td>{{ p.max_weight }}</td>
            <td>{{ p.price }}</td>
            <td>{{ p.remark }}</td>
            <td>{{ p.created_at }}</td>
            <td>
              <el-switch
                  v-model="p.status"
                  style="margin-right: 20px;"
                  active-text="啟用"
                  inactive-text="禁用"
                  @change="handlerChangeStatus(p.id, p.status)"
              >
              </el-switch>
              <el-button type="primary" @click="openDialog(p.id)">編輯</el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
      :title="editId ? '編輯郵包模型' : '添加郵包模型'"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="60%"
    >
      <el-form ref="form" :model="form" :rules="formRules" label-width="12rem">
        <el-form-item prop="name" label="名稱">
          <el-input v-model="form.name" placeholder="輸入郵包模型名稱。e.g:S袋，M袋，XL袋" clearable />
        </el-form-item>

        <el-form-item prop="code" label="Code（不能包含中文）">
          <el-input maxlength="5" show-word-limit v-model="form.code" placeholder="輸入郵包模型Code" clearable />
        </el-form-item>

        <el-form-item prop="type" label="類型">
          <el-radio-group v-model="form.type">
            <el-radio-button :label="1">門店寄件</el-radio-button>
            <el-radio-button :label="2">上門取件</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="form.type === 1" prop="materiel" label="郵包材質">
          <el-input v-model="form.materiel" placeholder="輸入郵包材質。e.g:紙袋，紙箱 " clearable />
        </el-form-item>

        <el-form-item v-if="form.type === 1" prop="length" label="長度（CM）">
          <el-input-number v-model="form.length" :min="1" :precision="1" />
        </el-form-item>

        <el-form-item v-if="form.type === 1" prop="width" label="寬度（CM）">
          <el-input-number v-model="form.width" :min="1" :precision="1" />
        </el-form-item>

        <el-form-item v-if="form.type === 1" prop="height" label="高度（CM）">
          <el-input-number v-model="form.height" :min="0" :precision="1" />
        </el-form-item>

        <el-form-item prop="max_weight" label="最大重量（G）">
          <el-input-number v-model="form.max_weight" :min="0" :precision="1" />
        </el-form-item>

        <el-form-item prop="price" label="價格（HKD）">
          <el-input-number v-model="form.price" :min="1" :precision="2" />
        </el-form-item>

        <el-form-item prop="remark" label="備註">
          <el-input v-model="form.remark" clearable />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'ParcelModels',
  mounted() {
    this.$store.dispatch('fetchParcelModels');
  },
  data() {
    return {
      editId: null,
      dialogVisible: false,
      form: {},
      formRules: {
        name: { required: true, message: '請輸入郵包模型名稱', trigger: 'blur' },
        type: { required: true, message: '請選擇郵包類型', trigger: 'change' },
        code: [
          { pattern: /^\w{1,5}$/, message: '郵包模型Code格式錯誤', trigger: 'blur' },
          { required: true, message: '請輸入郵包模型名稱', trigger: 'blur' },
        ],
        materiel: { required: true, message: '請輸入郵包材質', trigger: 'blur' },
        length: { required: true, message: '請輸入郵包長度', trigger: 'blur' },
        width: { required: true, message: '請輸入郵包寬度', trigger: 'blur' },
        max_weight: { required: true, message: '請輸入最大重量', trigger: 'blur' },
        price: { required: true, message: '請輸入郵包價格', trigger: 'blur' },
      },
    };
  },
  computed: {
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        code: '',
        type: 1,
        materiel: '',
        length: 0,
        width: 0,
        height: 0,
        max_weight: 1,
        price: null,
        currency: 'HKD',
        remark: '',
      };
    },
    openDialog(id = null) {
      this.editId = id;
      if (id) {
        this.form = _.find(this.parcelModels, { id });
      } else {
        this.initForm();
      }
      this.dialogVisible = true;
    },
    handlerChangeStatus(id, status) {
      if (id) {
        const loading = getLoadingInstance();
        const data = _.find(this.parcelModels, { id });
        data.status = status;
        this.$store.dispatch('saveParcelModel', data).then(() => {
          this.dialogVisible = false;
        }).catch(error => showErrorMessage(error)).finally(() => {
          loading.close();
        });
      }
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$store.dispatch('saveParcelModel', this.form).then(() => {
            this.dialogVisible = false;
          }).catch(error => showErrorMessage(error)).finally(() => {
            loading.close();
          });
        }
      });
    },
  },
};
</script>
