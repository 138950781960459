<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>創建郵包號</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="form" :model="form" :rules="formRules" label-width="6rem">
          <el-form-item prop="post_office_id" label="門店">
            <el-select class="w-50" v-model="form.post_office_id" filterable clearable>
              <el-option
                v-for="post_office in currentUser.post_offices"
                :key="post_office.id"
                :label="post_office.name"
                :value="post_office.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申請數量" required v-if="form.apply.length">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th>郵包類型</th>
                  <th>郵包信息</th>
                  <th>數量</th>
                </tr>
                <tr v-for="(item, key) in form.apply" :key="item.parcel_model_id">
                  <td>{{ item.name }}</td>
                  <td v-html="renderParcelModelInfo(item.parcel_model_id)"></td>
                  <td class="pb-4">
                    <el-form-item :prop="`apply.${key}.amount`" :rules="formRules.amount">
                      <el-input-number :min="0" v-model.number="item.amount" :step="10" />
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">申請郵包號</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'CreateParcel',
  mounted() {
    this.$store.dispatch('fetchParcelModels').then(() => {
      this.enableParcelModels.forEach((pm) => {
        this.form.apply.push({
          parcel_model_id: pm.id,
          name: pm.name,
          amount: 0,
        });
      });
    });
  },
  data() {
    return {
      form: {
        post_office_id: null,
        apply: [],
      },
      formRules: {
        amount: [
          { required: true, message: '請輸入申請數量', trigger: 'blur' },
          { type: 'number', message: '請輸入正確的數量', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUser', 'enableParcelModels',
    ]),
  },
  methods: {
    renderParcelModelInfo(id) {
      const parcelModel = _.find(this.enableParcelModels, { id });
      if (parcelModel) {
        const size = _.filter([parcelModel.length, parcelModel.width, parcelModel.height])
          .join('&times;');
        return `
          <ul>
            <li>尺寸：${size} (CM)</li>
            <li>材料：${parcelModel.materiel}</li>
            <li>最大重量：${parcelModel.max_weight} (G)</li>
            <li>价格：HK$${parcelModel.price}</li>
            <li>备注：${parcelModel.remark}</li>
          </ul>
        `;
      }
      return '';
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$api.parcel.save(this.form).then(() => {
            this.$notify.success({
              title: '已創建郵包號',
            });
            this.$router.push({ name: 'parcels' });
          }).catch(error => showErrorMessage(error)).finally(() => {
            loading.close();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">

</style>
