<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>郵包列表</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="5rem">
          <el-form-item label="郵包號" prop="code">
            <el-input v-model.trim="searchForm.code" clearable @keyup.enter.native="submit()" />
          </el-form-item>

          <el-form-item label="門店" prop="post_office_id">
            <el-select v-model="searchForm.post_office_id" style="width: 280px" clearable filterable>
              <el-option label="未關聯門店" :value="-1"></el-option>
              <el-option
                v-for="post_office in currentUser.post_offices"
                :key="post_office.id"
                :label="post_office.name"
                :value="post_office.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="郵包類型" prop="parcel_model_id">
            <el-select v-model="searchForm.parcel_model_id" clearable>
              <el-option
                v-for="parcel_model in parcelModels"
                :key="parcel_model.id"
                :label="parcel_model.name"
                :value="parcel_model.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="關聯訂單" prop="is_used">
            <el-select v-model="searchForm.is_used" style="width: 280px" clearable filterable>
              <el-option label="未關聯" :value="-1"></el-option>
              <el-option label="已關聯" :value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="date" label="創建日期">
            <el-date-picker
                v-model="searchForm.date"
                type="daterange"
                align="right"
                range-separator="至"
                start-placeholder="開始日期"
                end-placeholder="結束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submit">查詢</el-button>
          </el-form-item>

          <el-form-item>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="card card-default">

      <div class="card-body">

        <div class="operators">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="exportData">導出數據</el-button>
          <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="batchPrint">打印Label</el-button>
          <el-button type="primary" class="ml-2" :disabled="allBind" @click="batchUnbind">解除綁定</el-button>
        </div>

        <v-client-table :data="parcels" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template slot="id"  slot-scope="props">
          <el-checkbox v-model="checked" :label="props.row.id"  @change="handCheckedParcelsChange"></el-checkbox>
          </template>

          <template slot="code" slot-scope="props">
            <el-tooltip placement="top" effect="light">
              <div slot="content" v-html="props.row.barcode">
                <!--<img :src="`data:image/png;base64,${props.row.barcode}`" alt="barcode"/>-->
              </div>
              <span>{{ props.row.code }}</span>
            </el-tooltip>
          </template>

          <template slot="post_office_id" slot-scope="props">
            {{ props.row.post_office_id ? postOfficeKey[props.row.post_office_id].name : '未關聯' }}
            <span v-if="props.row.post_office_id && !postOfficeKey[props.row.post_office_id].status">
              （已停用）
            </span>
          </template>

          <template slot="parcel_model_id" slot-scope="props">
            {{ parcelModelsKey[props.row.parcel_model_id].name }}
          </template>

          <template slot="order_id" slot-scope="props">
            <router-link
              :to="{ name: 'order-detail', params: { id: props.row.order_id }}"
              v-if="props.row.order_id"
            >
              {{ props.row.order_id }}
            </router-link>
          </template>

          <template slot="operation" slot-scope="props">
            <el-popconfirm title="確定要刪除這個包裹號碼？" @confirm="removeParcel(props.row.id)">
              <button
                slot="reference"
                class="btn btn-danger btn-sm ml-2"
                v-if="!props.row.order_id"
              >
                刪除
              </button>
            </el-popconfirm>

            <button
              @click="unbind(props.row.code)"
              class="btn btn-warning btn-sm ml-2"
              v-if="props.row.post_office_id"
            >
              解除綁定
            </button>

            <button
              @click="showBarcode(props.row.barcode)"
              class="btn btn-warning btn-sm ml-2"
            >
              Barcode
            </button>
          </template>
        </v-client-table>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import download from 'downloadjs';
import printJS from 'print-js';
import { mapGetters, mapState } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import appConfig from '../../app.config';

export default {
  name: 'Parcels',
  mounted() {
    if (!this.parcelModels.length) {
      this.$store.dispatch('fetchParcelModels');
    }
    this.submit();
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checked: [],
      parcels: [],
      searchForm: {
        id: '',
        code: '',
        parcel_model_id: null,
        post_office_id: null,
      },
      tableConfigs: {
        columns: ['id', 'code', 'post_office_id', 'parcel_model_id', 'order_id', 'created_at', 'operation'],
        options: {
          headings: {
            id: 'ID',
            code: '郵包號碼',
            parcel_model_id: '郵包類型',
            post_office_id: '所屬門店',
            order_id: '關聯訂單',
            created_at: '創建時間',
            operation: '操作',
          },
          filterable: false,
          sortable: ['id', 'code', 'created_at', 'post_office_id', 'parcel_model_id'],
          perPage: 50,
          // perPageValues: [100, 200, 300, 500],
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三個月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
    ...mapGetters([
      'currentUser',
    ]),
    parcelModelsKey() {
      return _.keyBy(this.parcelModels, 'id');
    },
    postOfficeKey() {
      return _.keyBy(this.currentUser.post_offices, 'id');
    },
    allBind() {
      if (this.checked.length > 0) {
        const ids = [];
        _.forEach(this.checked, (id) => {
          const parcel = _.find(this.parcels, { id });
          if (parcel && parcel.post_office_id === null) {
            ids.push(id);
          }
        });
        return Boolean(ids.length);
      }
      return true;
    },
  },
  methods: {
    batchPrint() {
      this.$api.parcel.batchPrint({ ids: this.checked }).then(({ data: response }) => {
        printJS({
          printable: response,
          type: 'raw-html',
          showModal: true,
        });
      });
    },
    showBarcode(barcode) {
      // const content = `<img src="data:image/png;${barcode}" alt="barcode" />`;
      const content = `<div v-html="${barcode}"></div>`;
      // const content = `<span style="color: red;">${order.issue.question_admin}</span>  ： ${order.issue.question}`;
      this.$alert(content, '', {
        showConfirmButton: false,
        showClose: false,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true,
      });
    },
    batchUnbind() {
      this.$api.parcel.batchUnbind({ ids: this.checked }).then(() => {
        this.checked.forEach((value) => {
          const parcel = _.find(this.parcels, { id: value });
          parcel.post_office_id = null;
        });
        this.$notify.success({
          title: '已解除綁定',
        });
        this.checked = [];
        this.checkAll = false;
        this.isIndeterminate = false;
      }).catch(error => showErrorMessage(error));
    },
    exportData() {
      const loading = getLoadingInstance();
      const { API_HOST } = appConfig;
      const formData = new FormData();
      _.forEach(this.checked, id => formData.append('ids[]', id));
      const url = `${API_HOST}/parcel-export`;
      const x = new XMLHttpRequest();
      x.open('POST', url, true);
      x.responseType = 'blob';
      x.onload = function (e) {
        loading.close();
        download(e.target.response, '包裹列表.xls', e.target.response.type);
      };
      x.send(formData);
    },
    handleCheckAllChange(val) {
      this.checked = val ? _.map(this.parcels, 'id') : [];
      this.isIndeterminate = false;
    },
    handCheckedParcelsChange() {
      const checkedCount = this.checked.length;
      this.checkAll = checkedCount === this.parcels.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.parcels.length;
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.submit();
    },
    submit() {
      const loading = getLoadingInstance();
      const params = {};
      _.forEach(this.searchForm, (val, key) => {
        if (val) {
          params[key] = val;
        }
      });
      this.$api.parcel.parcels(params).then(({ data: response }) => {
        this.parcels = [...response.data];

        this.checked = [];
        this.checkAll = false;
        this.isIndeterminate = false;
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    removeParcel(id) {
      this.$api.parcel.removeParcel(id).then(() => {
        this.parcels = _.reject(this.parcels, { id });
      }).catch(error => showErrorMessage(error));
    },
    unbind(code) {
      this.$api.parcel.bind(code, null).then(() => {
        const parcel = _.find(this.parcels, { code });
        parcel.post_office_id = null;
        this.$notify.success({
          title: '已解除綁定',
        });
      }).catch(error => showErrorMessage(error));
    },
  },
};
</script>
